import {
  Button,
  Checkbox, Dialog, DialogTitle, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Paper, Select, Snackbar, TextField,
} from '@material-ui/core';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

const ContactForm = () => {
  const [nda, setNda] = useState(false);
  const [policy, setPolicy] = useState(false);
  const [info, setInfo] = useState(false);
  const [topic, setTopic] = useState('web');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState('');
  const handleSelectChange = (event) => {
    setTopic(event.target.value);
  };
  const { handleSubmit, register, reset } = useForm();
  const onSubmit = (data) => {
    console.log(data);
    data.nda = nda;
    data.topic = topic;
    if (policy) {
      setLoading(true);
      fetch('/.netlify/functions/iqc', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
        .then((r) => {
          setLoading(false);
          if (r.ok) {
            reset({
              first: '', last: '', email: '', phone: '', msg: '',
            });
            setSuccess('Thank you for sending your message. We will get back to you shortly.');
          } else {
            setSuccess('An error occurred when sending the message. Please try again.');
          }
        })
        .catch((err) => {
          setLoading(false);
          setSuccess('An error occurred. Please try again.');
        });
    } else {
      setSuccess('Please take a look at our GDPR personal data processing statement.');
    }
  };
  return (
    <form
      style={{ marginTop: '30px' }}
      onSubmit={handleSubmit(onSubmit)}
    >

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Inquiry topic</InputLabel>
            <Select
              fullWidth
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={topic}
              onChange={handleSelectChange}
            >
              <MenuItem value="web">Web development</MenuItem>
              <MenuItem value="mobile">Mobile development</MenuItem>
              <MenuItem value="erp">CRM/ERP systems</MenuItem>
              <MenuItem value="pwa">Progressive Web Apps (PWA)</MenuItem>
              <MenuItem value="devops">DevOps services</MenuItem>
              <MenuItem value="consulting">IT Consulting</MenuItem>
              <MenuItem value="nextcloud">Nextcloud services</MenuItem>
              <MenuItem value="inne">Other inquires</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <TextField inputRef={register({ required: true })} name="first" label="First name" variant="standard" fullWidth />
        </Grid>
        <Grid item xs={6}>
          <TextField inputRef={register} name="last" label="Last name" variant="standard" fullWidth />
        </Grid>
        <Grid item xs={6}>
          <TextField inputRef={register} name="email" type="email" label="e-mail" variant="standard" fullWidth />
        </Grid>
        <Grid item xs={6}>
          <TextField inputRef={register} name="phone" type="tel" label="Phone No." variant="standard" fullWidth />
        </Grid>
        <Grid item xs={12}>
          <TextField inputRef={register} name="msg" label="Message" multiline variant="standard" fullWidth />
        </Grid>
        <Grid item xs={12}>

          <FormControlLabel
            control={(
              <Checkbox
                checked={policy}
                name="policy"
                color="primary"
                onClick={() => { setPolicy(!policy); }}
              />
        )}
            label={(
              <>
                I accept the
                {' '}
                <a onClick={() => { setInfo(true); }}>personal data processing policy</a>
                .
              </>
)}
            style={{ marginBottom: '6px' }}
          />
          <FormControlLabel
            control={(
              <Checkbox
                checked={nda}
                name="nda"
                color="primary"
                onClick={() => { setNda(!nda); }}
              />
        )}
            label="Please prepare an NDA prior to contacting me."
          />

        </Grid>
        <Grid item xs={12}>
          <Button type="submit" variant="contained" color="primary">Wyślij</Button>
        </Grid>
      </Grid>
      <Dialog open={info} onClose={() => { setInfo(false); }}>
        <DialogTitle>
          Personal data processing statement
        </DialogTitle>
        <div>
          <p style={{ padding: '0px 12px' }}>
            Under the GDPR law of the EU and its member states, I consent to my personal data
            provided in the contact form being processed for the purpose of answering my business
            inquiry. I am aware of the fact that I have the right to access and update the personal
            data that I have willfully provided and that I may withdraw my consent to my personal
            data being processed at any time. The data administrator is IQcode
            Technologie Internetowe Paweł Kuffel, address: Chodkiewicza Street 7/22, 02-593 Warsaw,
            Poland.
          </p>
          <Button disabled={loading} style={{ margin: '20px' }} variant="contained" onClick={() => { setInfo(false); }}>
            Close
          </Button>
        </div>
      </Dialog>
      <Snackbar open={success !== ''} autoHideDuration={6000} message={success} onClose={() => { setSuccess(''); }} />
    </form>
  );
};

export default ContactForm;
