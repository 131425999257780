import { Container } from '@material-ui/core';
import React from 'react';
import { ParallaxBanner } from 'react-scroll-parallax';
import Img from 'gatsby-image';

const SubLayout = ({
  title, subtitle, children, img, bg,
}) => (
  <>
    <ParallaxBanner
      className="parallax-container parallax-container-t"
      style={{ background: bg || 'linear-gradient(#1467999e, #411455ad)' }}
      layers={[
        {
          image: img,
          amount: 0.5,
        },
      ]}
    >
      <div className="parallax">
        <Img src="/img/apps-mobile-bg.jpg" />
      </div>
      <Container>
        <div className="page-title-container">
          <h1 className="page-title">
            {title}
          </h1>
          <h5>{subtitle}</h5>
        </div>
      </Container>
    </ParallaxBanner>
    {children}
  </>
);

export default SubLayout;
