import React from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import { Container, Fab, Grid } from '@material-ui/core';
import PhoneIcon from '@material-ui/icons/Phone';
import MailIcon from '@material-ui/icons/Mail';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import LocationIcon from '@material-ui/icons/LocationOn';
import EuroIcon from '@material-ui/icons/Euro';
import ScheduleIcon from '@material-ui/icons/Schedule';
import { Helmet } from 'react-helmet';
import styled from '@emotion/styled';
import FeatureCardsSection from '../components/featureCardsSection';
import KompleksowaUsluga from '../../assets/svg/iqcode-technologie-stack.svg';
import CzasRealizacji from '../../assets/svg/metodyki-zwinne-agile.svg';
import Gwarancja from '../../assets/svg/gwarancje-wsparcie-sla.svg';
import SubLayout from '../layouts/subLayout';
import AllServices from '../components/allServices';
import LogoMain from '../../assets/svg/logo-full-alt-big.svg';
import ContactForm from '../components/contactForm';
import theme from '../theme/theme';
import SEO from '../components/seo';
import DlaczegoWarto from '../components/dlaczegoWarto';

const ContactCard = styled.div`
  padding:  4px 16px;
  background-color: white;
  box-shadow: 3px 5px 17px 5px rgba(0, 0, 0, 0.08);
  border-radius: 16px;

  li {
    margin-top: 12px;
    font-size: 18px;
  }

  svg {
    vertical-align: sub;
    color: ${theme.primary};
  }

  h4 {
    font-size: 22px;
    text-align: center;
    font-weight: 600;
    margin-bottom: 22px;
    color: #333;
    svg {
      margin-bottom: 15px;
      font-size: 30px;
    }
  }

  &:hover {
        box-shadow: 3px 5px 17px 5px rgba(0, 0, 0, 0.16);
        background-color: ${theme.primary};
        color: white;
        transition: 0.5s ease;
        h5, li, svg, h4 {
          color: white;
        }
    }
`;

const ContactPage = () => {
  const data = useStaticQuery(graphql`
        query ContactImages {
            kompleksowacontact: file(
                relativePath: { eq: "kompleksowa-usluga.png" }
            ) {
                id
                childImageSharp {
                    fluid(quality: 95, traceSVG: { threshold: 50 }) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `);

  return (
    <SubLayout
      title={(
        <>
          <span>Contact</span>
        </>
              )}
      subtitle="Let's talk about your project"
      img="/img/biznes-kontakt.jpg"
    >
      <SEO title="Contact - iqcode Software House" description="Contact with iqcode, a Warsaw-based software house specialized in the creation of Progressive Web Apps (PWA) and microservices backend applications." />
      <Helmet>
        <script type="application/ld+json">
          {`
        {
          "@context": "https://schema.org",
          "@type": "Organization",
          "url": "https://iqcode.pl",
          "name": "iqcode Technologie Internetowe Paweł Kuffel",
          "contactPoint": {
            "@type": "ContactPoint",
            "telephone": "+48-608-775-280",
            "contactType": "Sales"
          }
        }
      `}
        </script>
      </Helmet>
      <Container className="mwrs">
        <Grid spacing={4} container>

          <Grid item xs={12} sm={6}>
            <h2>
              Contact
              {' '}
              <span>Info</span>
            </h2>
            <div style={{
              display: 'block', margin: '16px 0px', padding: '5px 0px',
            }}
            >
              <p>
                Due to the COVID-19 pandemic, we have switched to remote work.
              </p>
            </div>
            <Grid spacing={3} container>

              <Grid item sm={6} xs={12}>
                <a href="tel:+48511639264">
                  <ContactCard>
                    <h4>
                      <PhoneIcon />
                      <br />
                      +48 608 775 280
                    </h4>
                  </ContactCard>
                </a>
              </Grid>
              <Grid item sm={6} xs={12}>
                <a href="mailto:hello@iqcode.io">
                  <ContactCard>
                    <h4>
                      <MailIcon />
                      <br />
                      hello@iqcode.io
                    </h4>
                  </ContactCard>
                </a>
              </Grid>
              <Grid item sm={12} xs={12}>
                <ContactCard>
                  <h4>
                    Company
                    {' '}
                    <span>Data</span>
                  </h4>
                  <ul>
                    <li>
                      <AccountBalanceIcon />
                      {' '}
                      IQcode Technologie Internetowe Paweł
                      Kuffel
                    </li>
                    <li>
                      <LocationIcon />
                      {' '}
                      Chodkiewicza 7/22, 02-593 Warszawa
                    </li>
                    <li>
                      <EuroIcon />
                      {' '}
                      PL7811977904 (VAT EU)
                    </li>
                    <li>
                      <ScheduleIcon />
                      {' '}
                      mon - fri, 9am - 6pm CET
                    </li>
                  </ul>
                </ContactCard>
              </Grid>
            </Grid>

          </Grid>
          <Grid item xs={12} sm={6}>
            <h2>
              Contact
              {' '}
              <span>Form</span>
            </h2>
            <ContactForm />
          </Grid>
        </Grid>
      </Container>

      <div
        className="promo-light"
        style={{ marginBottom: '60px', marginTop: '30px' }}
      >
        <div className="promo-light-diagonal" />
        <div className="container rel">
          <div className="row">
            <div className="col s12 m12 l6 xl6">
              <div className="promo-left">
                <h3>52 MINUTES</h3>
                <h6>
                  Average time of first reply to a new lead sent during our working hours.
                </h6>
              </div>
            </div>
            <div className="col s12 m12 l6 xl6">
              <div className="promo-right">
                <h3>72 HOURS</h3>
                <h6>
                  90% of analyzed projects we analyze receive a fixed-price estimate within this timeframe.
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Container>
        <Grid container>
          <Grid item sm={12} md={8}>
            <h2>
              A reliable partner for
              {' '}
              <span>your IT project</span>
            </h2>
            <p>
              While we are a Warsaw-based software development company, our
              scope is not limited to the Polish domestic market and we offer
              our services to businesses all around the EU.
            </p>
            <p style={{ marginBottom: '50px' }}>
              Virtually all of our projects end up in a long-lasting partnership custom-tailored to
              our customer's needs and expectations, which we consistently aim to exceed.
              Being a relatively young company has given us
              an unique opportunity to use cutting-edge technologies devoid of technical debt from
              day zero, without having to maintain legacy applications written in languages and
              frameworks of the past.
            </p>
          </Grid>
          <Grid item sm={12} md={4} style={{ padding: '30px' }}>
            <LogoMain className="inline-svg-fw" />
          </Grid>
        </Grid>
      </Container>
      <div className="promo overlay-holder z-depth-3">
        <div className="diagonal-overlay-light" />
        <div className="rel">
          <h3>
            Let's discuss your
            {' '}
            <span>Project</span>
            !
          </h3>
          <a className="btn" href="mailto:hello@iqcode.io">Contact us!</a>
        </div>
      </div>
      <DlaczegoWarto />
      <AllServices />

      <Fab
        color="primary"
        aria-label="call"
        style={{
          position: 'fixed', bottom: '16px', right: '16px', zIndex: 1000,
        }}
        href="tel:+48608775280"
      >
        <PhoneIcon />
      </Fab>
    </SubLayout>
  );
};

export default ContactPage;
